import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
  },
  link: {
    textDecoration: 'underline',
  },
  editButton: {
    visibility: 'hidden',
    flexShrink: 0,
  },
  circularProgress: {
    marginTop: -5,
    marginBottom: -5,
    marginLeft: 8,
    flexShrink: 0,
  },
  value: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    lineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  viewMode: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    fontSize: 14,
  },
});

export default useStyles;
