import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { approvalListActions as actions } from 'redux/actions';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { idToName } from 'constants/folders';

import TableSettingsProvider from 'components/TableSettingsProvider';
import ImmoSubNavigation from 'components/Layout/Navigation/ImmoSubNavigation';

import { COLUMN_LIST } from 'constants/immoApprovalList';

import ImmoApprovalsTable from './ImmoApprovalsTable';

const ImmoApprovalsPage = () => {
  const dispatch = useDispatch();

  const { folderId } = useParams();
  const folderName = idToName[folderId];

  useEffect(() => {
    dispatch(actions.getItemListRequest({}));

    return () => {
      dispatch(actions.reset());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  return (
    <Layout
      app="immos"
      breadCrumbsContent={[
        <Typography key="3" color="text.primary">
          {folderName}
        </Typography>,
      ]}
      subNav={<ImmoSubNavigation />}
      withEditMode
    >
      <TableSettingsProvider tableId="immoApprovalsList" columns={COLUMN_LIST}>
        <ImmoApprovalsTable />
      </TableSettingsProvider>
    </Layout>
  );
};

export default ImmoApprovalsPage;
