import React, { useRef } from 'react';
import { format } from 'date-fns';

import { DatePicker } from '@mui/x-date-pickers';

import useStyles from './EditableDateCellEditMode.styles';

const EditableDateCellEditMode = ({
  initialValue,
  onSubmit,
  onFocus,
  onBlur,
  onClose,
}) => {
  const classes = useStyles();

  const valueRef = useRef();

  const handleSubmit = async () => {
    onSubmit(
      valueRef.current ? format(valueRef.current, 'yyyy-MM-dd') : valueRef.current,
    );
  };

  const handleBlur = () => {
    onBlur();
    handleSubmit();
  };

  const handleClose = () => {
    onClose();
    handleSubmit();
  };

  const handleChange = (newValue) => {
    valueRef.current = newValue;
  };

  return (
    <DatePicker
      className={classes.root}
      defaultValue={initialValue ? new Date(initialValue) : undefined}
      format="yyyy-MM-dd"
      slotProps={{
        field: {
          onFocus,
          onBlur: handleBlur,
        },
        textField: {
          variant: 'standard',
          InputProps: {
            disableUnderline: true,
          },
          inputProps: {
            style: {
              padding: 0,
            },
          },
        },
      }}
      onClose={handleClose}
      onChange={handleChange}
    />
  );
};

export default EditableDateCellEditMode;
