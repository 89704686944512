import React, { useState } from 'react';

import Button from '@mui/material/Button';
import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';
import { Link, useParams } from 'react-router-dom';
import useTask from 'hooks/useTask';
import ProcessingButtonWithError from 'components/ProcessingButtonWithError';
import { useDispatch } from 'react-redux';

import { processingPopupActions } from 'redux/actions';

const ScanProcessingButton = ({ item }) => {
  const dispatch = useDispatch();
  const { task, setTask } = useTask({ fileItemId: item.id });
  const [isSubmitting, setIsSubmitting] = useState();
  const { immoId, orgName } = useParams();

  const handleSplit = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await request({
        method: 'post',
        url: `/files/${item.id}/_prepare_split`,
        data: {
          parent_id: 'temp',
        },
      });
      dispatch(processingPopupActions.fetchProcessingTasks());
      setTask(data);
    } catch (e) {
      NM.error('Something went wrong');
    }
    setIsSubmitting(false);
  };

  const renderSplitButton = () => (
    <Button
      variant="text"
      disabled={isSubmitting}
      onClick={() => handleSplit()}
    >
      Split
    </Button>
  );

  if (task === undefined) {
    return null;
  }

  if (task === null) {
    return renderSplitButton();
  }

  const renderSplitPreparation = () => {
    if (task.pages_total) {
      return `Preparing splitting (${task.pages_processed}/${task.pages_total})...`;
    }

    return 'Preparing splitting...';
  };

  if (task.status === 'processing') {
    switch (task.type) {
      case 'split_preparation':
        return <Button disabled variant="text">{renderSplitPreparation()}</Button>;
      case 'split_finalizing':
        return <Button disabled variant="text">Splitting...</Button>;
      case 'classification':
        return <Button disabled variant="text">Sub docs classification...</Button>;
      case 'metadata_extraction':
        return <Button disabled variant="text">Sub docs metadata extraction...</Button>;
      case 'name_generation':
        return <Button disabled variant="text">Sub docs name generation...</Button>;
      default:
        return <Button disabled variant="text">Processing...</Button>;
    }
  }

  if (task.status === 'completed') {
    switch (task.type) {
      case 'classification':
        return <Button disabled variant="text">Sub docs classification...</Button>;
      case 'metadata_extraction':
        return <Button disabled variant="text">Sub docs metadata extraction...</Button>;
      default:
        break;
    }
  }

  const renderVerifySplittingButton = () => (
    <Button
      component={Link}
      variant="text"
      to={`/${orgName}/${immoId}/scans/${item.id}/splitting`}
    >
      Verify splitting
    </Button>
  );

  if (task.type === 'split_preparation') {
    if (task.status === 'completed') {
      return renderVerifySplittingButton();
    }

    return (
      <ProcessingButtonWithError errorMessage="AI service error">
        {renderSplitButton()}
      </ProcessingButtonWithError>
    );
  }

  if (task.type === 'split_finalizing') {
    if (task.status === 'completed') {
      return (
        <Button
          component={Link}
          variant="text"
          to={`/${orgName}/${immoId}/scans/${item.id}/sub-docs`}
        >
          View sub docs
        </Button>
      );
    }

    return (
      <ProcessingButtonWithError>
        {renderVerifySplittingButton()}
      </ProcessingButtonWithError>
    );
  }

  return (
    <Button
      component={Link}
      variant="text"
      to={`/${orgName}/${immoId}/scans/${item.id}/sub-docs`}
    >
      View sub docs
    </Button>
  );
};

export default ScanProcessingButton;
