import React from 'react';

import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';

import useStyles from './TextCell.styles';

const TextCell = ({ text }) => {
  const classes = useStyles();

  return (
    <TableCell>
      <Tooltip title={text} enterDelay={1000} placement="top" classes={{ tooltip: classes.tooltip }}>
        <div className={classes.body}>
          {text}
        </div>
      </Tooltip>
    </TableCell>
  );
};

export default TextCell;
