import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { fileViewDialogActions } from 'redux/actions';

const useFilePreview = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const openFilePreview = ({ fileId, pageNumber }) => {
    searchParams.set('preview_file', fileId);

    setSearchParams(searchParams);

    if (pageNumber) {
      dispatch(fileViewDialogActions.openDialog({
        fileItemId: fileId,
        pageNumber,
      }));
    }
  };

  const closeFilePreview = () => {
    searchParams.delete('preview_file');

    setSearchParams(searchParams);
  };

  return {
    openFilePreview,
    closeFilePreview,
  };
};

export default useFilePreview;
