import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { Link } from 'react-router-dom';

import { mainSelectors } from 'redux/selectors';

import EditableTextCellEditMode from './EditableTextCellEditMode';
import EditableNumberCellEditMode from './EditableNumberCellEditMode';
import EditableDateCellEditMode from './EditableDateCellEditMode';

import useStyles from './EditableCell.styles';

const EditableCell = ({
  type = 'STRING', // STRING | DOUBLE | INTEGER | DATE | BOOLEAN | ARRAY_STRING
  value,
  displayValue,
  isSubmitting,
  isProcessing,
  inputProps,
  to,
  forceEditMode,
  onChange,
  onClick,
  ...otherProps
}) => {
  const classes = useStyles();

  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  const isGlobalEditMode = useSelector(mainSelectors.isEditModeSelector);

  const isEditMode = forceEditMode || isGlobalEditMode;

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleClose = () => {
    setFocused(false);
    setHovered(false);
  };

  const handleSubmit = (newValue) => {
    if (value !== newValue) {
      onChange(newValue, { onSuccess: () => {} });
    }
  };

  const renderContent = () => {
    if (type === 'BOOLEAN') {
      return (
        <Checkbox
          sx={{
            pointerEvents: isEditMode ? 'auto' : 'none',
          }}
          defaultChecked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
      );
    }

    if (isEditMode && (hovered || focused || isSubmitting)) {
      switch (type) {
        case 'DOUBLE':
        case 'INTEGER':
          return (
            <EditableNumberCellEditMode
              type={type}
              initialValue={value}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onSubmit={handleSubmit}
            />
          );
        case 'DATE':
          return (
            <EditableDateCellEditMode
              initialValue={value}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onClose={handleClose}
              onSubmit={handleSubmit}
            />
          );
        default:
          return (
            <EditableTextCellEditMode
              initialValue={value}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onSubmit={handleSubmit}
            />
          );
      }
    }

    const renderViewModeValue = () => {
      switch (type) {
        case 'STRING':
          return (
            <Tooltip
              title={displayValue || value}
              enterDelay={1000}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <div className={classes.value}>{displayValue || value}</div>
            </Tooltip>
          );
        default:
          return value;
      }
    };

    return (
      <div className={classes.viewMode}>
        {renderViewModeValue()}
        {isProcessing && <CircularProgress className={classes.circularProgress} size={24} />}
      </div>
    );
  };

  const isClickable = onClick && !isEditMode;

  const handleClick = () => {
    if (isClickable) {
      onClick();
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <TableCell
      sx={{
        cursor: isClickable ? 'pointer' : undefined,
      }}
      className={cx(classes.root, to && classes.link)}
      component={isEditMode || !to ? undefined : Link}
      to={to}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      {...otherProps}
    >
      {renderContent()}
    </TableCell>
  );
};

export default EditableCell;
