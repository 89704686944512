import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDidMount, useWillUnmount } from 'hooks';
import { useNavigate } from 'react-router-dom';

import { DragDropContext } from 'react-beautiful-dnd';

import { NotificationManager as NM } from 'react-notifications';

import { pdfjs, Document } from 'react-pdf';

import { fileSplittingActions as actions } from 'redux/actions';
import { fileSplittingSelectors as selectors } from 'redux/selectors';
import {
  LinearProgress, Button, Stack, IconButton,
} from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';

import { request } from 'api';

import { removeSplittingState, setSplittingState } from 'utils/localstorage/splittingLocalstorage';
import SplittingPageRow from './SplittingPageRow';

import getSubDocumentsForRequest from './utils/getSubDocumentsForRequest';

import SplittingViewZoomController from './SplittingViewZoomController';

import useStyles from './SplittingView.styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const isUsingWindows = navigator.platform.indexOf('Win') >= 0;

const SplittingView = ({ fileItemId, finishSplitUrl, backUrl }) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState();
  const [pdfObj, setPdfObj] = useState();
  const [metaKeyPressed, setMetaKeyPressed] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const dispatch = useDispatch();
  const {
    isInited,
    pdfUrl,
    subDocs,
    preparationTaskId,
    showConfidences,
    base64ByPageNumber,
    fileItem,
    highlightedPageNumber,
    zoom,
  } = useSelector(selectors.rootSelector);

  const selectedPages = useSelector(selectors.selectedPagesSelector);
  const countSelectedPages = useSelector(selectors.countSelectedPagesSelector);

  const navigate = useNavigate();

  useDidMount(() => {
    dispatch(actions.initRequest({ fileItemId }));
  });

  useWillUnmount(() => {
    dispatch(actions.reset());
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      const value = isUsingWindows ? event.ctrlKey : event.metaKey;

      if (value) {
        setMetaKeyPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      const value = isUsingWindows ? event.key === 'Control' : event.key === 'Meta';

      if (value) {
        setMetaKeyPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (subDocs) {
      setSplittingState(fileItemId, subDocs);
    }
  }, [subDocs, fileItemId]);

  const itemData = useMemo(() => ({
    subDocs,
    preparationTaskId,
    showConfidences: process.env.REACT_APP_ENV === 'prod' ? false : showConfidences,
    base64ByPageNumber,
    pdfObj,
    highlightedPageNumber,
    zoom,
  }), [subDocs, preparationTaskId, showConfidences, base64ByPageNumber, pdfObj, highlightedPageNumber, zoom]);

  if (!isInited) {
    return <LinearProgress />;
  }

  const handleCancel = () => {
    navigate(backUrl);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await request({
        method: 'post',
        url: finishSplitUrl,
        data: {
          parent_id: 'temp',
          sub_documents: getSubDocumentsForRequest(subDocs),
        },
      });
      removeSplittingState(fileItemId);
      navigate(backUrl);
    } catch (e) {
      NM.error('Something went wrong');
    }

    setIsSubmitting(false);
  };

  const handleDragStart = (start) => {
    const draggablePageId = start.draggableId;

    if (countSelectedPages > 0 && !selectedPages[draggablePageId]) {
      dispatch(actions.selectPage({ pageNumber: start.draggableId }));
    }

    setIsDragging(true);
  };

  const handleDragEnd = (result) => {
    setIsDragging(false);

    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (countSelectedPages > 1) {
      dispatch(actions.movePages({
        pageNumbers: Object.keys(selectedPages).map((s) => Number(s)),
        targetSubDocIndex: Number(destination.droppableId),
        targetPageIndex: destination.index,
      }));
      dispatch(actions.resetSelectedPages());
    } else {
      dispatch(actions.movePage({
        sourceSubDocIndex: Number(source.droppableId),
        sourcePageIndex: source.index,
        targetSubDocIndex: Number(destination.droppableId),
        targetPageIndex: destination.index,
      }));
    }
  };

  const handlePdfLoad = (pdf) => {
    setPdfObj(pdf);
  };

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <Document style={{ display: 'none' }} file={pdfUrl} onLoadSuccess={handlePdfLoad} />
      <div style={{ paddingTop: 64 }}>
        <header className={classes.header}>
          <div className={classes.leftSide}>
            <div><b>Splitting</b></div>
            <div className={classes.fileNameWrapper}>
              <span className={classes.fileName}>{fileItem.name}</span>
              <IconButton size="small" component="a" href={pdfUrl} target="_blank">
                <DownloadIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
          <SplittingViewZoomController />
          <Stack direction="row" spacing={2}>
            <Button variant="text" onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" disabled={isSubmitting} onClick={handleSubmit}>Split</Button>
          </Stack>
        </header>
        {subDocs.map((item, index) => (
          <SplittingPageRow
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            index={index}
            data={itemData}
            metaKeyPressed={metaKeyPressed}
            selectedPages={selectedPages}
            countSelectedPages={countSelectedPages}
            isDragging={isDragging}
          />
        ))}
      </div>
    </DragDropContext>
  );
};

export default SplittingView;
