import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { NotificationManager as NM } from 'react-notifications';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { fileListSelectors as selectors } from 'redux/selectors';
import { fileListActions as actions } from 'redux/actions';

import useExportSubDocs from 'hooks/export/useExportSubDocs';
import useFilePreview from 'hooks/useFilePreview';

import { request } from 'api';

import FileDeletionDialog from './FileDeletionDialog';

// import useStyles from './FileRawActions.styles';

export default function FileRawMenu({
  item,
  withExportSubDocs,
  withExtractMetadata = true,
  withDelete = true,
  withMoreMenu = true,
  disabled,
  menuItems,
}) {
  const dispatch = useDispatch();
  const { openFilePreview } = useFilePreview();
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(null);
  const open = Boolean(anchorEl);
  const task = useSelector(selectors.itemTaskSelector(item.id));
  const { immoId, orgName } = useParams();
  const exportSubDocs = useExportSubDocs();

  const canBeResplitted = task && (task.type === 'split_finalizing' && task.status !== 'processing');

  const handleMenuButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = async () => {
    try {
      const { data } = await request({
        method: 'post',
        url: '/document-download-links/_generate',
        data: {
          file_item_id: item.file_item_id || item.id,
          direct_download: true,
        },
      });
      window.open(data.signed_link);
    } catch (e) {
      NM.error('Something went wrong during resolving the link');
    }
  };

  const handleDelete = () => {
    handleMenuClose();
    setIsDeletionDialogOpen(true);
  };

  const handleExportSubDocs = () => {
    handleMenuClose();
    exportSubDocs(item);
  };

  const handleExtractMetadata = async () => {
    try {
      dispatch(actions.updateFile({ file: { ...item, extractingMetadata: true } }));
      const { data } = await request({
        method: 'post',
        url: `/files/${item.id}/_extract_metadata`,
        data: {},
      });
      dispatch(actions.updateFile({ file: data }));
      NM.success('Metadata extraction complete');
    } catch (e) {
      dispatch(actions.updateFile({ file: { ...item, extractingMetadata: false } }));
      NM.error('Something went wrong');
    }
  };

  const handleGenerateFilename = async () => {
    try {
      const { data } = await request({
        method: 'post',
        url: `/files/${item.id}/_generate-name`,
        data: {},
      });

      dispatch(actions.updateFile({ file: data }));

      NM.success('Generation name complete');
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  const handleView = () => {
    openFilePreview({ fileId: item.file_item_id || item.id });
  };

  return (
    <Stack direction="row">
      <IconButton onClick={handleView}>
        <VisibilityIcon />
      </IconButton>
      <IconButton onClick={handleDownload}>
        <DownloadIcon />
      </IconButton>
      {withMoreMenu && (
        <IconButton disabled={disabled} onClick={handleMenuButtonClick}>
          <MoreVertIcon />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClick={handleMenuClose}
        onClose={handleMenuClose}
      >
        {menuItems}
        {withExportSubDocs && (
          <MenuItem onClick={handleExportSubDocs}>
            Export sub documents
          </MenuItem>
        )}
        {withExtractMetadata && item.ai_metadata && (
          <MenuItem onClick={handleExtractMetadata}>
            Re-extract metadata
          </MenuItem>
        )}
        {withExtractMetadata && item.ai_metadata && (
          <MenuItem onClick={handleGenerateFilename}>
            Re-generate filename
          </MenuItem>
        )}
        {canBeResplitted && (
          <MenuItem component={Link} to={`/${orgName}/${immoId}/scans/${item.id}/splitting`}>
            Re-split
          </MenuItem>
        )}
        {withDelete && (
          <MenuItem sx={{ color: 'error.main' }} onClick={handleDelete}>
            Delete
          </MenuItem>
        )}
      </Menu>
      {isDeletionDialogOpen && (
        <FileDeletionDialog item={item} onClose={() => setIsDeletionDialogOpen(false)} />
      )}
    </Stack>
  );
}
