import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { Divider } from '@mui/material';

import { mainSelectors } from 'redux/selectors';
import { mainActions } from 'redux/actions';

import { idToName } from 'constants/folders';
import { Link, useParams } from 'react-router-dom';
import useTenantConfig from 'hooks/tenantConfig/useTenantConfig';
import { useDidMount } from 'hooks';
import AskImmoMateDialog from 'components/Layout/Navigation/ImmoSubNavigation/AskImmoMateDialog';

function ImmoSubNavigation() {
  const { immoId, folderId, orgName } = useParams();
  const config = useTenantConfig();
  const [askImmoMateOpen, setAskImmoMateOpen] = useState(false);

  const dispatch = useDispatch();

  const isExpanded = useSelector(mainSelectors.isNavigationExpandedSelector);

  useDidMount(() => {
    if (isExpanded) {
      setTimeout(() => {
        dispatch(mainActions.expandNavigation());
      }, 0);
    }
  });

  const renderItem = (num, name) => (
    <MenuItem
      key={name}
      sx={{ alignItems: 'flex-start' }}
      component={Link}
      to={`/${orgName}/${immoId}/${name}`}
      selected={name === folderId}
    >
      <Typography
        sx={{ fontSize: 14, whiteSpace: 'normal', marginLeft: '8px' }}
        variant="inherit"
      >
        {idToName[name]}
      </Typography>
    </MenuItem>
  );

  return (
    <>
      <MenuList>
        {config.immoCardOverview && [
          renderItem('', 'overview'),
          <Divider key="divider1" />,
        ]}
        {renderItem('', 'approvals')}
        <Divider key="divider1" />
        {renderItem('', 'docs')}
        <Divider />
        {renderItem('', 'scans')}
        {config.askImmoMate && [
          <Divider key="divider2" />,
          <MenuItem
            key="ask"
            sx={{ alignItems: 'flex-start' }}
            onClick={() => setAskImmoMateOpen(true)}
          >
            <Typography
              sx={{ fontSize: 14, whiteSpace: 'normal', marginLeft: '8px' }}
              variant="inherit"
            >
              Ask ImmoMate
            </Typography>
          </MenuItem>,
        ]}
      </MenuList>
      {askImmoMateOpen && <AskImmoMateDialog onClose={() => setAskImmoMateOpen(false)} />}
    </>
  );
}

export default ImmoSubNavigation;
