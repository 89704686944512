import React, { useState, useRef } from 'react';

import { Chip } from '@mui/material';

import useStyles from './FilesDndZone.styles';

const FilesDndZone = ({ children, onDrop }) => {
  const classes = useStyles();
  const dragCounter = useRef(0);

  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = () => {
    if (dragCounter.current === 0) {
      setIsDragging(true);
    }
    dragCounter.current += 1;
  };

  const handleDragLeave = () => {
    dragCounter.current -= 1;

    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    dragCounter.current = 0;
    setIsDragging(false);
    onDrop(Array.from(e.dataTransfer.files));
  };

  const handleDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div
      className={classes.root}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
    >
      {children}
      {isDragging && (
        <div className={classes.dndPlaceholder}>
          <Chip label="Drop files to upload them" color="primary" />
        </div>
      )}
    </div>
  );
};

export default FilesDndZone;
