import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fileListActions as actions } from 'redux/actions';

import request from 'api/request';

const useTask = ({
  fileItemId, fetchOnMount = true, onTaskComplete, onTaskTypeUpdate,
}) => {
  const [task, setTask] = useState();
  const taskRef = useRef(task);
  const dispatch = useDispatch();

  taskRef.current = task;

  const fetchTask = async () => {
    try {
      const { data } = await request({
        method: 'get',
        url: '/tasks',
        params: {
          file_item_id: fileItemId,
          limit: 1,
        },
      });

      const oldTask = taskRef.current;
      const newTask = data.tasks[0];

      dispatch(actions.setItemTask({ itemId: fileItemId, task: newTask }));

      setTask(newTask || null);
      if (onTaskComplete && oldTask && newTask && newTask.status !== oldTask.status && newTask.status === 'completed') {
        onTaskComplete(newTask);
      }

      if (onTaskTypeUpdate && oldTask?.type !== newTask.type) {
        onTaskTypeUpdate(oldTask, newTask);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    if (fetchOnMount) {
      fetchTask();
    }

    const intervalId = setInterval(() => {
      if (taskRef.current?.status === 'processing') {
        fetchTask();
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    task,
    setTask,
    fetchTask,
  };
};

export default useTask;
