import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import { documentFilters } from 'constants/folders';

import { filtersActions as actions } from 'redux/actions';
import { filtersSelectors as selectors, mainSelectors } from 'redux/selectors';
import useDocumentClassesById from 'hooks/documentClasses/useDocumentClassesById';

const ImmoDocsFilterChips = ({ onTypeFilterChange }) => {
  const dispatch = useDispatch();
  const documentClassesById = useDocumentClassesById();
  const group = useSelector(selectors.groupSelector);
  const types = useSelector(selectors.typesSelector);
  const isEn = useSelector(mainSelectors.isEnSelector);

  const handleRemoveGroup = () => {
    dispatch(actions.setGroupFilter({ value: null }));
  };

  const handleRemoveDocumentType = (id) => {
    const newTypes = types.filter((typeId) => typeId !== id);

    onTypeFilterChange(newTypes);
  };

  const chips = [];

  if (group) {
    chips.push(
      <Chip
        key="group"
        label={documentFilters[group]}
        onDelete={handleRemoveGroup}
      />,
    );
  }

  types.forEach((typeId) => {
    if (documentClassesById) {
      chips.push(
        <Chip
          key={typeId}
          label={isEn ? documentClassesById[typeId].name : documentClassesById[typeId].name_de}
          onDelete={() => handleRemoveDocumentType(typeId)}
        />,
      );
    }
  });

  if (chips.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" spacing="8px">
      {chips}
    </Stack>
  );
};

export default ImmoDocsFilterChips;
