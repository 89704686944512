import React from 'react';
import {
  BrowserRouter as Router, Routes, Route, useParams,
} from 'react-router-dom';
import ScansPage from 'pages/ScansPage';
import SubdocsPage from 'pages/SubdocsPage';
import ImmoDocsPage from 'pages/ImmoDocsPage';
import ScanSplittingPage from 'pages/ScanSplittingPage';
import ImmoListPage from 'pages/ImmoListPage';
import InboxPage from 'pages/InboxPage';
import AskImmoPage from 'pages/AskImmoPage';
import ImmoOverviewPage from 'pages/ImmoOverviewPage';
import InboxItemSplittingPage from 'pages/InboxItemSplittingPage';
import InboxItemSubDocsPage from 'pages/InboxItemSubDocsPage';
import ImmoApprovalsPage from 'pages/ImmoApprovalsPage';
import SearchPage from 'pages/SearchPage';
import FileItemPreview from 'pages/FilePreviewPage';

import PrivateRoute from './PrivateRoute';

const FolderSwitchPage = () => {
  const { folderId } = useParams();

  switch (folderId) {
    case 'overview':
      return <ImmoOverviewPage />;
    case 'approvals':
      return <ImmoApprovalsPage />;
    case 'docs':
      return <ImmoDocsPage />;
    case 'scans':
      return <ScansPage />;
    case 'ask':
      return <AskImmoPage />;
    default:
      return null;
  }
};

const privateRoutes = [
  {
    path: '/:orgName',
    element: <ImmoListPage />,
  },
  {
    path: '/:orgName/:immoId/:folderId/:subFolder?',
    element: <FolderSwitchPage />,
  },
  {
    path: '/:orgName/:immoId/scans/:scanId/splitting',
    element: <ScanSplittingPage />,
  },
  {
    path: '/:orgName/:immoId/scans/:scanId/sub-docs',
    element: <SubdocsPage />,
  },
  {
    path: '/',
    element: <PrivateRoute />,
  },
  {
    path: '/:immoId/:folderId',
    element: <PrivateRoute />,
  },
  {
    path: '/:immoId/scans/*',
    element: <PrivateRoute />,
  },
  {
    path: '/:orgName/inbox',
    element: <InboxPage key={1} inboxType="DEFAULT" />,
  },
  {
    path: '/:orgName/inbox/:itemId/splitting',
    element: <InboxItemSplittingPage key={2} inboxType="DEFAULT" />,
  },
  {
    path: '/:orgName/inbox/:itemId/sub-docs',
    element: <InboxItemSubDocsPage key={3} inboxType="DEFAULT" />,
  },
  {
    path: '/:orgName/balance-import',
    element: <InboxPage key={4} inboxType="BALANCE_IMPORT" />,
  },
  {
    path: '/:orgName/balance-import/:itemId/splitting',
    element: <InboxItemSplittingPage key={5} inboxType="BALANCE_IMPORT" />,
  },
  {
    path: '/:orgName/balance-import/:itemId/sub-docs',
    element: <InboxItemSubDocsPage key={6} inboxType="BALANCE_IMPORT" />,
  },
  {
    path: '/:orgName/search',
    element: <SearchPage />,
  },
  {
    path: '/:orgName/files/:fileItemId',
    element: <FileItemPreview />,
  },
];

function AppRouter() {
  return (
    <Router>
      <Routes>
        {privateRoutes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute>{element}</PrivateRoute>}
          />
        ))}
      </Routes>
    </Router>
  );
}

export default AppRouter;
