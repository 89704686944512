import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import MetadataCell from 'components/MetadataCell';

import useDocumentClassesById from 'hooks/documentClasses/useDocumentClassesById';

export default function ViewMetadataDialog({ fileItem, onItemUpdate, onClose }) {
  const [tab, setTab] = useState('main');

  const documentClassesById = useDocumentClassesById() || {};

  const tablesStructure = documentClassesById[fileItem.document_class_id]?.tables || [];

  const metadata = fileItem.ai_metadata;

  const handleClose = () => {
    onClose();
  };

  const mainContent = (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableBody>
          {metadata.metadata_list.map((itemMetadata, index) => (
            <TableRow
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <TableCell component="th" scope="row">
                {itemMetadata.name}
              </TableCell>
              <MetadataCell
                forceEditMode
                fileItem={fileItem}
                metadata={itemMetadata}
                onFileItemUpdate={onItemUpdate}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const tables = metadata.tables_list || [];

  const tabsContent = {};

  tables.forEach((table) => {
    tabsContent[`table_${table.name}`] = table;
  });

  const renderTabContent = () => {
    const tabContent = tabsContent[tab];

    if (!tabContent) {
      return mainContent;
    }

    const { lines } = tabContent;
    const fields = tablesStructure.find(({ id }) => id === tabContent.id)?.fields;

    if (!lines || !fields) {
      return mainContent;
    }

    const fieldsById = {};

    fields.forEach((field) => {
      fieldsById[field.id] = field;
    });

    return (
      <TableContainer>
        <Table stickyHeader sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {lines.map((column) => (
                <TableCell key={column.id}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {lines[0].values.map((_, rowIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={rowIndex}>
                {lines.map((column) => (
                  <MetadataCell
                    key={column.id}
                    forceEditMode
                    fileItem={fileItem}
                    tableId={tabContent.id}
                    rowIndex={rowIndex}
                    metadata={{
                      id: column.id,
                      value: column.values[rowIndex],
                      type: fieldsById[column.id]?.field_type,
                    }}
                    onFileItemUpdate={onItemUpdate}
                  />

                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderContent = () => {
    if (tables.length === 0) {
      return mainContent;
    }

    return (
      <>
        <Tabs
          value={tab}
          onChange={(e, value) => setTab(value)}
        >
          <Tab value="main" label="Main" />
          {tables.map((table) => (
            <Tab key={table.name} value={`table_${table.name}`} label={table.name} />
          ))}
        </Tabs>
        {renderTabContent()}
      </>
    );
  };

  return (
    <Dialog
      open
      maxWidth="xl"
      onClose={handleClose}
    >
      <DialogTitle>
        Metadata
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        {renderContent()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
