import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { fileListActions as actions } from 'redux/actions';
import { useParams, Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { withCurrentScan } from 'hocs/withCurrentScan';
import { idToName } from 'constants/folders';

import FilesTable from 'components/FilesTable';
import SearchBox from 'components/SearchBox';
import ImmoSubNavigation from 'components/Layout/Navigation/ImmoSubNavigation';

const SubDocsPage = ({ scan }) => {
  const dispatch = useDispatch();
  const { scanId, immoId, orgName } = useParams();

  useEffect(() => {
    dispatch(actions.resetSort());
    dispatch(actions.setOriginId({ value: scanId }));
    dispatch(actions.getFileListRequest({}));

    return () => {
      dispatch(actions.reset());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanId]);

  const handleSearch = (searchTerm) => {
    dispatch(actions.setSearchTerm({ value: searchTerm }));
    dispatch(actions.getFileListRequest({ }));
  };

  return (
    <Layout
      app="immos"
      searchBox={(
        <SearchBox onSearch={handleSearch} />
      )}
      breadCrumbsContent={[
        <Link underline="hover" key="1" color="inherit" component={RouterLink} to={`/${orgName}/${immoId}/scans`}>
          {idToName.scans}
        </Link>,
        <Typography key="4" color="text.primary">
          {scan?.name}
        </Typography>,
        <Typography key="5" color="text.primary">
          Sub docs
        </Typography>,
      ]}
      subNav={<ImmoSubNavigation />}
      withEditMode
    >
      <FilesTable />
    </Layout>
  );
};

export default withCurrentScan(SubDocsPage);
