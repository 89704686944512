import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager as NM } from 'react-notifications';

import Dialog from '@mui/material/Dialog';

import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import request from 'api/request';
import { useDidMount } from 'hooks';

import useFilePreview from 'hooks/useFilePreview';
import { mainActions } from 'redux/actions';

import useStyles from './FileViewDialog.styles';

export default function FileViewDialog({ fileItemId, pageNumber }) {
  const classes = useStyles();

  const [url, setUrl] = useState();
  const [fileItem, setFileItem] = useState({ });
  const { closeFilePreview } = useFilePreview();
  const dispatch = useDispatch();

  const fileItemRef = useRef(fileItem);

  fileItemRef.current = fileItem;

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      getFilePlugin: {
        fileNameGenerator: () => fileItemRef.current.name,
      },
    },
  });

  const handleClose = () => {
    closeFilePreview();
    setTimeout(() => {
      dispatch(mainActions.setHighlightedItemId({ value: fileItemId }));
    }, 0);
  };

  const handleResolveUrl = async () => {
    try {
      const { data } = await request({
        method: 'post',
        url: '/document-download-links/_generate',
        data: {
          file_item_id: fileItemId,
        },
      });
      setUrl(data.signed_link);
    } catch (e) {
      NM.error('Something went wrong during resolving the link');
    }
  };

  const handleResolveFileItem = async () => {
    try {
      const { data } = await request({
        method: 'get',
        url: `/files/${fileItemId}`,
      });

      setFileItem(data);
    } catch (e) {
      NM.error('Something went wrong during resolving the link');
    }
  };

  useDidMount(() => {
    handleResolveUrl();
    handleResolveFileItem();
  });

  const renderContent = () => {
    if (!url) {
      return null;
    }

    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 64px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ padding: 8, fontWeight: 500 }}>
          {fileItem.name}
        </div>
        <div className={classes.viewer} style={{ minHeight: 0 }}>
          <Viewer
            fileUrl={url}
            initialPage={pageNumber}
            plugins={[defaultLayoutPluginInstance]}
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open
      maxWidth="xl"
      fullWidth
      disableRestoreFocus
      onClose={handleClose}
    >
      {renderContent()}
    </Dialog>
  );
}
