import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

import EditableTextCell from 'components/UIKit/EditableCell';
import TextCell from 'components/UIKit/TextCell';
import { regenerateFileNameConfirmationDialogActions } from 'redux/actions';

const MetadataCell = ({
  forceEditMode,
  fileItem,
  metadata,
  tableId,
  rowIndex,
  onFileItemUpdate = () => {},
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileItemId = fileItem.id;

  const handleBaseSubmit = async (newValue, options) => {
    setIsSubmitting(true);

    try {
      const { data } = await request({
        method: 'patch',
        url: `/files/${fileItemId}/metadata-field/${metadata.id}`,
        data: {
          new_value: newValue || '',
        },
      });

      onFileItemUpdate(data);

      if (metadata.is_file_name_based_on) {
        dispatch(regenerateFileNameConfirmationDialogActions.openDialog({
          fileItem,
          onRegenerateSuccess: ((newItem) => onFileItemUpdate(newItem)),
        }));
      }

      if (options?.onSuccess) {
        options.onSuccess();
      }
    } catch (error) {
      NM.error('Something went wrong');
    }

    setIsSubmitting(false);
  };

  const handleTableSubmit = async (newValue) => {
    setIsSubmitting(true);

    try {
      const { data } = await request({
        method: 'patch',
        url: `/files/${fileItemId}/tables/${tableId}`,
        data: {
          row: rowIndex,
          new_values: [
            {
              id: metadata.id,
              value: newValue || '',
            },
          ],
        },
      });

      onFileItemUpdate(data);
    } catch (error) {
      NM.error('Something went wrong');
    }

    setIsSubmitting(false);
  };

  const handleSubmit = (newValue, options) => {
    if (tableId) {
      handleTableSubmit(newValue);
    } else {
      handleBaseSubmit(newValue, options);
    }
  };

  if (metadata.id) {
    if (metadata.type === 'BOOLEAN') {
      return (
        <EditableTextCell
          forceEditMode={forceEditMode}
          type={metadata.type}
          value={metadata.value === 'Yes'}
          onChange={(newValue) => handleSubmit(newValue ? 'Yes' : 'No')}
        />
      );
    }

    return (
      <EditableTextCell
        forceEditMode={forceEditMode}
        type={metadata.type}
        value={metadata.value}
        displayValue={metadata.value}
        isSubmitting={isSubmitting}
        onChange={handleSubmit}
      />
    );
  }

  return <TextCell value={metadata.value} />;
};

export default MetadataCell;
