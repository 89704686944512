import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { NotificationManager as NM } from 'react-notifications';

import Layout from 'components/Layout';

import { setIsGlobalLoading } from 'redux/actions';

import request from 'api/request';

import { useDidMount } from 'hooks';

const FilePreviewPage = () => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState();

  const { fileItemId } = useParams();

  const handleResolveUrl = async () => {
    dispatch(setIsGlobalLoading(true));
    try {
      const { data } = await request({
        method: 'post',
        url: '/document-download-links/_generate',
        data: {
          file_item_id: fileItemId,
        },
      });
      setUrl(data.signed_link);
    } catch (e) {
      NM.error('Something went wrong');
    }
    dispatch(setIsGlobalLoading(false));
  };

  useDidMount(() => {
    handleResolveUrl();
  });

  const renderContent = () => {
    if (!url) {
      return null;
    }

    return (
      <iframe
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        title="document"
        src={url}
      />
    );
  };

  return (
    <Layout>
      {renderContent()}
    </Layout>
  );
};

export default FilePreviewPage;
