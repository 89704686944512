import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { documentFilters } from 'constants/folders';

import { filtersActions as actions } from 'redux/actions';
import { filtersSelectors as selectors } from 'redux/selectors';

const options = [
  'EIGENTUMER',
  'STAMMADATEN_ZUR_IMMOBILIE',
  'VERTRAGE_AND_VOLLMACHTEN',
  'OBJEKTBEGEHUNGED_ZAHLERSTANDE',
  'JAHRESABRECHNUNG_WIRTSCHAFTSPLAN',
  'VERSAMMLUNG_AND_BESCHLUSSE',
  'DIENSTLEISTER',
  'BUCHHALTUNG',
  'INSTANDSETZUNG_UND_HALTUNG',
  'OTHER',
];

const ImmoDocsSubFolderSelect = () => {
  const dispatch = useDispatch();
  const group = useSelector(selectors.groupSelector);

  const handleChange = (event) => {
    const { value } = event.target;

    dispatch(actions.setGroupFilter({ value: value === 'all' ? null : value }));
  };

  return (
    <FormControl size="small" sx={{ width: 150 }}>
      <InputLabel>Group</InputLabel>
      <Select
        label="Group"
        value={group || ''}
        onChange={handleChange}
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur();
          }, 0);
        }}
      >
        <MenuItem value="all">All</MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>{documentFilters[option]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ImmoDocsSubFolderSelect;
