import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import { documentFilters } from 'constants/folders';

import { searchActions as actions, filtersActions } from 'redux/actions';
import { filtersSelectors as selectors, mainSelectors } from 'redux/selectors';
import useDocumentClassesById from 'hooks/documentClasses/useDocumentClassesById';

const DocsFilterChips = () => {
  const dispatch = useDispatch();
  const documentClassesById = useDocumentClassesById();

  const group = useSelector(selectors.groupSelector);
  const types = useSelector(selectors.typesSelector);
  const immos = useSelector(selectors.immosSelector);

  const isEn = useSelector(mainSelectors.isEnSelector);

  const handleRemoveGroup = () => {
    dispatch(filtersActions.setGroupFilter({ value: null }));
    dispatch(actions.search());
  };

  const handleRemoveDocumentType = (id) => {
    const newTypes = types.filter((typeId) => typeId !== id);

    dispatch(filtersActions.setTypeFilter({ value: newTypes }));
    dispatch(actions.search());
  };

  const handleRemoveImmo = (immoId) => {
    const newImmos = immos.filter((immo) => immo.id !== immoId);

    dispatch(filtersActions.setImmosFilter({ value: newImmos }));
    dispatch(actions.search());
  };

  const chips = [];

  if (group) {
    chips.push(
      <Chip
        key="group"
        label={documentFilters[group]}
        onDelete={handleRemoveGroup}
      />,
    );
  }

  types.forEach((typeId) => {
    if (documentClassesById) {
      chips.push(
        <Chip
          key={typeId}
          label={isEn ? documentClassesById[typeId].name : documentClassesById[typeId].name_de}
          onDelete={() => handleRemoveDocumentType(typeId)}
        />,
      );
    }
  });

  immos.forEach((immo) => {
    if (documentClassesById) {
      chips.push(
        <Chip
          key={immo.id}
          label={immo.name}
          onDelete={() => handleRemoveImmo(immo.id)}
        />,
      );
    }
  });

  if (chips.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" spacing="8px" flexWrap="wrap" useFlexGap>
      {chips}
    </Stack>
  );
};

export default DocsFilterChips;
