import React, { useState } from 'react';

import TableCell from '@mui/material/TableCell';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

import EditableTextCell from 'components/UIKit/EditableCell';

import useFilePreview from 'hooks/useFilePreview';

import pdfIcon from './pdf-icon.png';

const FileNameTableCell = ({ item, onItemUpdate }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isGenerating = item.isNameGenerating;
  const { openFilePreview } = useFilePreview();

  const handleSubmit = async (newName, { onSuccess }) => {
    setIsSubmitting(true);

    try {
      const { data } = await request({
        method: 'patch',
        url: `/files/${item.id}`,
        data: {
          name: `${newName}.pdf`,
        },
      });
      onItemUpdate(data);
      onSuccess();
    } catch (error) {
      NM.error('Something went wrong');
    }

    setIsSubmitting(false);
  };

  const handleClick = () => {
    openFilePreview({ fileId: item.file_item_id || item.id });
  };

  return (
    <>
      <TableCell style={{
        paddingRight: 0, height: 36, width: 0, paddingTop: 17,
      }}
      >
        <img alt="" src={pdfIcon} style={{ width: 16, height: 16, display: 'block' }} />
      </TableCell>
      <EditableTextCell
        style={{ fontWeight: 500 }}
        value={item.name.replace(/\.[^/.]+$/, '')}
        displayValue={item.name}
        isSubmitting={isSubmitting}
        isProcessing={isGenerating}
        onChange={handleSubmit}
        onClick={handleClick}
      />
    </>
  );
};

export default FileNameTableCell;
