import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import { NotificationManager as NM } from 'react-notifications';

import { fileListActions as actions } from 'redux/actions';

import request from 'api/request';
import { useDidMount } from 'hooks';

const SubDocProcessButton = ({ item, onViewMetadata }) => {
  const dispatch = useDispatch();
  const [isInited, setIsInited] = useState(false);
  const [isClassifying, setIsClassifying] = useState(false);
  const [isClassified, setIsClassified] = useState(false);
  const [isExtractingMetadata, setIsExtractingMetadata] = useState(false);

  const resolveLastTask = async () => {
    try {
      const { data } = await request({
        method: 'get',
        url: '/tasks',
        params: {
          file_item_id: item.id,
          limit: 1,
        },
      });

      const status = data.tasks[0]?.status;

      if (status === 'completed') {
        setIsClassified(true);
      }

      setIsInited(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useDidMount(() => {
    resolveLastTask();
  });

  const handleClassify = async () => {
    setIsClassifying(true);
    try {
      const { data } = await request({
        method: 'post',
        url: `/files/${item.id}/_classify`,
        data: {},
      });
      dispatch(actions.updateFile({ file: data.classified_file_item }));
      setIsClassified(true);
      NM.success('Classification complete');
    } catch (e) {
      NM.error('Something went wrong');
    }
    setIsClassifying(false);
  };

  const handleExtractMetadata = async () => {
    setIsExtractingMetadata(true);
    try {
      const { data } = await request({
        method: 'post',
        url: `/files/${item.id}/_extract_metadata`,
        data: {},
      });
      dispatch(actions.updateFile({ file: data }));
      NM.success('Metadata extraction complete');
    } catch (e) {
      NM.error('Something went wrong');
    }
    setIsExtractingMetadata(false);
  };

  if (!isInited) {
    return null;
  }

  if (isClassifying) {
    return (
      <Button disabled variant="text">Classifying...</Button>
    );
  }

  if (isExtractingMetadata || item.extractingMetadata) {
    return (
      <Button disabled variant="text">Extracting metadata...</Button>
    );
  }

  if (item.ai_metadata) {
    return (
      <Button
        variant="text"
        onClick={() => onViewMetadata(item)}
      >
        View metadata
      </Button>
    );
  }

  if (!isClassified) {
    return <Button onClick={() => handleClassify()} variant="text">Classify</Button>;
  }

  return (
    <Button
      variant="text"
      onClick={() => handleExtractMetadata()}
    >
      Extract metadata
    </Button>
  );
};

export default SubDocProcessButton;
