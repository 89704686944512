/* eslint-disable no-buffer-constructor */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Highlighter from 'react-highlight-words';

import { Chip } from '@mui/material';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Buffer } from 'buffer';

import { useDidMount } from 'hooks';

import { fileViewDialogActions } from 'redux/actions';

import { mainSelectors } from 'redux/selectors';

import { request } from 'api';

import useFilePreview from 'hooks/useFilePreview';
import useStyles from './SearchCard.styles';

const SearchCard = ({ result, searchTerm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [base64, setBase64] = useState();
  const { openFilePreview } = useFilePreview();

  const resolveImage = async () => {
    try {
      const response = await request({
        method: 'get',
        url: `/files/${result.file_item_id}/pages/1/thumbnail?width=10&height=10`,
        responseType: 'arraybuffer',
      });

      setBase64(`data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useDidMount(() => {
    resolveImage();
  });

  const isEn = useSelector(mainSelectors.isEnSelector);

  const handlePageClick = (page) => {
    openFilePreview({ fileId: result.file_item_id });

    dispatch(fileViewDialogActions.openDialog({
      fileItemId: result.file_item_id,
      pageNumber: page?.page_number,
    }));
  };

  const renderCardActions = () => {
    if (result.pages.length > 0) {
      return result.pages.map((page) => (
        <Button key={page.page_number} size="small" onClick={() => handlePageClick(page)}>
          Page
          {' '}
          {page.page_number}
        </Button>
      ));
    }

    return (
      <Button size="small" onClick={() => handlePageClick()}>
        View document
      </Button>
    );
  };

  const documentType = isEn ? result.document_class_name : result.document_class_name_de;

  return (
    <Card style={{ display: 'flex' }}>
      <div className={classes.img} style={{ backgroundImage: `url(${base64})` }} />
      <div>
        <CardContent>
          {documentType && <Chip label={documentType} />}
          <Typography gutterBottom variant="h7" component="div" className={classes.cardTitle}>
            <Highlighter
              searchWords={searchTerm.split(' ')}
              textToHighlight={result.file_item_name}
            />
          </Typography>
          {result.immo_name && (
            <Typography variant="body2" color="text.secondary">
              Property:
              {' '}
              {result.immo_name}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          {renderCardActions(result)}
        </CardActions>
      </div>
    </Card>
  );
};

export default SearchCard;
