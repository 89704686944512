import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';

import { fileListActions as actions, fileCreationActions, filtersActions } from 'redux/actions';
import { fileListSelectors as selectors, filtersSelectors } from 'redux/selectors';

import { documentFilters } from 'constants/folders';

import Layout from 'components/Layout';
import FilesTable from 'components/FilesTable';
import UploadButton from 'components/UploadButton';
import FilesDndZone from 'components/FilesDndZone';
import SearchBox from 'components/SearchBox';
import ImmoSubNavigation from 'components/Layout/Navigation/ImmoSubNavigation';

import { Stack } from '@mui/material';
import { useDidMount, useWillUnmount } from 'hooks';

import ImmoDocsGroupFilter from './ImmoDocsGroupFilter';
import ImmoDocsTypeFilter from './ImmoDocsTypeFilter';

import ImmoDocsFilterChips from './ImmoDocsFilterChips';

import useStyles from './ImmoDocsPage.styles';

const ImmoDocsPage = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();

  const group = useSelector(filtersSelectors.groupSelector);
  const fileTypes = useSelector(filtersSelectors.typesSelector);

  const sort = useSelector(selectors.sortSelector);

  const subFolderName = documentFilters[group] || 'Documents';

  const handleTypesFilterChange = (value) => {
    if (sort.isMetadata) {
      dispatch(actions.resetSort());
    }

    dispatch(filtersActions.setTypeFilter({ value }));
  };

  useDidMount(() => {
    dispatch(actions.resetSort());
  });

  useWillUnmount(() => {
    dispatch(actions.reset());
    dispatch(filtersActions.reset());
  });

  useEffect(() => {
    dispatch(actions.getFileListRequest({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, fileTypes]);

  const handleSearch = (searchTerm) => {
    dispatch(actions.setSearchTerm({ value: searchTerm }));
    dispatch(actions.getFileListRequest({ }));
  };

  const handleUpload = (files) => {
    dispatch(fileCreationActions.createFilesRequest({ files }));
  };

  const handleDropFiles = (files) => {
    dispatch(fileCreationActions.createFilesRequest({ files }));
  };

  return (
    <Layout
      app="immos"
      searchBox={(
        <Stack direction="row" spacing="16px" sx={{ alignItems: 'center' }}>
          <SearchBox onSearch={handleSearch} />
          <ImmoDocsGroupFilter />
          <ImmoDocsTypeFilter onChange={handleTypesFilterChange} />
        </Stack>
      )}
      breadCrumbsContent={[
        <Typography key="3" color="text.primary">
          {subFolderName}
        </Typography>,
      ]}
      newButton={group && <UploadButton onUpload={handleUpload} />}
      filterChips={<ImmoDocsFilterChips onTypeFilterChange={handleTypesFilterChange} />}
      subNav={<ImmoSubNavigation />}
      withEditMode
    >
      <FilesDndZone onDrop={handleDropFiles}>
        <FilesTable disableBulkProcessingActions />
      </FilesDndZone>
    </Layout>
  );
};

export default ImmoDocsPage;
