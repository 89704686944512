import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function FilesTableMenu({ onClassifyAll, onExtractMetadataAll, onExportZip }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClassifyAllClick = () => {
    handleMenuClose();
    onClassifyAll();
  };

  const handleExtractMetadataAllClick = () => {
    handleMenuClose();
    onExtractMetadataAll();
  };

  const handleExportSubDocs = () => {
    handleMenuClose();
    onExportZip();
  };

  const items = [];

  if (onExportZip) {
    items.push(
      <MenuItem key="export_zip" onClick={handleExportSubDocs}>
        Export ZIP
      </MenuItem>,
    );
  }

  if (onClassifyAll) {
    items.push(
      <MenuItem key="classify_all" onClick={handleClassifyAllClick}>
        Classify all
      </MenuItem>,
    );
  }

  if (onExtractMetadataAll) {
    items.push(
      <MenuItem key="export_metadata" onClick={handleExtractMetadataAllClick}>
        Extract metadata
      </MenuItem>,
    );
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <IconButton onClick={handleMenuButtonClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleMenuClose}
      >
        {items}
      </Menu>
    </>
  );
}
