import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { inboxItemSubDocsSelectors as selectors, mainSelectors } from 'redux/selectors';
import { inboxItemSubDocsActions as actions } from 'redux/actions';

import FileNameTableCell from 'components/FileNameTableCell';
import FileRawActions from 'components/FileRawActions';
import ColumnVisibilitySelect from 'components/TableSettingsProvider/ColumnVisibilitySelect';
import useTableSettings from 'components/TableSettingsProvider/useTableSettings';
import ViewMetadataDialog from 'components/dialogs/ViewMetadataDialog';

import formatDate from 'utils/formatDate';

import FileTypeCell from 'components/FileTypeCell';

import { CircularProgress } from '@mui/material';
import ApprovalChip from './ApprovalChip';
import SuggestedImmoCell from './SuggestedImmoCell';

import useStyles from './InboxItemSubDocsTable.styles';

function bytesToMB(bytes) {
  return bytes / 1024 / 1024;
}

const InboxItemSubDocsTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const items = useSelector(selectors.transformedItemsSelector);
  const { visibleColumns } = useTableSettings();
  const selectedLanguage = useSelector(mainSelectors.languageSelector);

  const highlightedItemId = useSelector(mainSelectors.highlightedItemIdSelector);

  const [selectedMetadataItem, setSelectedMetadataItem] = useState(null);

  const isEn = selectedLanguage === 'EN';

  const handleMetadataDialogClose = () => {
    setSelectedMetadataItem(null);
  };

  const handleItemUpdate = (fileItem) => {
    dispatch(actions.updateFile({ fileItem }));
  };

  const handlePushToImmo = (item) => {
    dispatch(actions.pushDocsToImmos({ approvalRequestIds: [item.approval.id] }));
  };

  const renderBodyCell = (column, item) => {
    const renderDescription = () => {
      if (isEn && item.summary) {
        return item.summary;
      }

      if (!isEn && item.summary_de) {
        return item.summary_de;
      }

      const aiMetadata = item.ai_metadata;

      if (!aiMetadata) {
        return null;
      }

      if (isEn) {
        return aiMetadata.metadata_list.find(({ name }) => name === 'Summary (eng)')?.value;
      }

      return aiMetadata.metadata_list.find(({ name }) => name === 'Summary (de)')?.value;
    };

    switch (column.type) {
      case 'DESCRIPTION': {
        const description = renderDescription();

        return (
          <TableCell>
            <Tooltip title={description} enterDelay={1000} placement="top" classes={{ tooltip: classes.tooltip }}>
              <div className={classes.description}>
                {description}
              </div>
            </Tooltip>
          </TableCell>
        );
      }
      case 'TYPE':
        return <FileTypeCell item={item} onItemUpdate={handleItemUpdate} />;
      case 'PAGES':
        return <TableCell align="right">{item.pages_count}</TableCell>;
      case 'CREATOR':
        return <TableCell>{item.created_by}</TableCell>;
      case 'DATE_ADDED':
        return <TableCell>{formatDate(item.created_at)}</TableCell>;
      case 'SIZE':
        return (
          <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
            {bytesToMB(Math.max(item.size, 0.1)).toFixed(3)}
            {' '}
            MB
          </TableCell>
        );
      case 'IMMO':
        return (
          <SuggestedImmoCell item={item} />
        );
      case 'APPROVAL':
        return (
          <TableCell align="left">
            {item.isPushing ? <CircularProgress size={24} /> : <ApprovalChip approval={item.approval} />}
          </TableCell>
        );
      default:
        return <TableCell />;
    }
  };

  return (
    <TableContainer sx={{ height: '100%' }}>
      <Table stickyHeader aria-label="sticky table" style={{ padding: 16 }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ paddingLeft: 0, paddingRight: 0, width: 40 }}
              colSpan={2}
            >
              Name
            </TableCell>
            {visibleColumns.map((column) => (
              <TableCell key={column.type} align={column.align}>
                {column.title}
              </TableCell>
            ))}
            <TableCell align="right" sx={{ width: 0 }}>
              <ColumnVisibilitySelect />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow
              key={item.id}
              hover
              selected={highlightedItemId === item.file_item_id}
              role="checkbox"
              tabIndex={-1}
            >
              <FileNameTableCell item={item} onItemUpdate={handleItemUpdate} />
              {visibleColumns.map((column) => (
                <Fragment key={column.type}>
                  {renderBodyCell(column, item)}
                </Fragment>
              ))}
              <TableCell>
                <FileRawActions
                  item={item}
                  withExtractMetadata={false}
                  withDelete={false}
                  menuItems={(
                    [
                      (item.ai_metadata && (
                        <MenuItem key={1} onClick={() => setSelectedMetadataItem(item)}>
                          View metadata
                        </MenuItem>
                      )),
                      <MenuItem key={2} disabled={!item.canBePushedToImmo} onClick={() => handlePushToImmo(item)}>
                        Push to immo
                      </MenuItem>,
                    ]
                  )}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {selectedMetadataItem && (
        <ViewMetadataDialog
          fileItem={selectedMetadataItem}
          onItemUpdate={handleItemUpdate}
          onClose={handleMetadataDialogClose}
        />
      )}
    </TableContainer>
  );
};

export default InboxItemSubDocsTable;
